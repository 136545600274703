<template>
  <v-main>
    <v-container
      fill-height
      fluid>
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm8
          md4>
          <v-card
            color="biscuit lighten-2"
            class="elevation-12">
            <v-toolbar
              color="biscuit"
              class="justify-center">
              <v-spacer />
              <v-toolbar-title>Request Reset Password</v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- <v-card-title class="mb-2 justify-center" style="background-color: #d9c7A3">
            <v-spacer />
            Admin Panel
            <v-spacer /> 
            </v-card-title> -->

            <v-card-text>
              <v-form
                v-model="valid"
                @submit.prevent="handleSubmit(save)"
                lazy-validation
                ref="resetform"
              >
                <v-text-field
                  type="password"
                  ref="password"
                  label="Set Password"
                  v-model="password1"
                  :rules="[rules.required, rules.minin, passwordMatchRule]"
                />

                <v-text-field
                  type="password"
                  label="Confirm Password"
                  v-model="password2"
                  :rules="[rules.required, rules.minin, passwordMatchRule]"
                />
                
              </v-form>
            </v-card-text>
            <!-- <v-divider class="mt-5"/> -->
              <!-- <v-card-text class="align-right justify-right text-caption">
                <p>Forgot password?</p>
              </v-card-text> -->
            <v-divider class="mt-5"/>
            <v-card-actions>
              <v-btn
                @click="reset"
                color="biscuit"
                >Reset
              </v-btn>
              <v-spacer/>
              <v-btn
                :disabled="!valid"
                
                align-center
                justify-center
                color="guacamole"
                @click="submit">Submit
                <v-progress-circular indeterminate color="biscuit" v-if="loading" />
              </v-btn>
              
            </v-card-actions>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :top="true"
            >
              {{ errorMessages }}
              <v-btn
                dark
                text
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
  </v-main>
</template>

<script>
import User from '../models/user';

export default {
  data: function () {
    return {
      valid: true,
      errorMessages: 'Incorrect login info',
      snackbar: false,
      color: 'general',
      showPassword: false,
      loading: false,
      password1: "",
      password2: "",
      token: "",
      rules: {
        required: (value) => !!value || "Field is required.",
        minin: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    }
  },

  computed: {
    loggedIn() {
      //return this.$store.state.auth.status.loggedIn;
      //return this.$store.getters['auth/authorized'] // FIXME
      return this.$store.state.newauth.isLoggedIn
    },
    passwordMatchRule() {
      return this.password1 === this.password2 || "Passwords must match";
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({name: 'Dashboard View'})
    }
  },
  mounted () {
    //console.log(this.$router.query)
    const urlParams = new URLSearchParams(window.location.search)
    this.token = urlParams.get("token")
    console.log(this.token)
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    submit () {
      if (this.$refs.resetform.validate()) 
        this.$store.dispatch('newauth/resetPassword', {password: this.password1, token: this.token})
    },
    reset () {
      this.password1 = "";
      this.password2 = "";
      this.$refs.resetform.reset()
    }
    // LOGIN METHOD using the old authservice.
    // login: function () {
    //   this.loading = true
    //   // let username = this.username
    //   // let password = this.password
    //   this.user.username = this.username
    //   this.user.password = this.password
    //   // this.$store.dispatch('auth/login', { username, password })
    //   this.$store.dispatch('auth/login', this.user)
    //     .then(() => {
    //       this.loading = false
    //       this.$router.push('/dashboard')
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.snackbar = true
    //       this.loading = false
    //     }
    //     )
    // }
  },
  metaInfo () {
    return {
      title: 'Reset Password'
    }
  }
}
</script>
